import React, {FC} from 'react';

type Props = {
    text: any
    className?: any
}

const MainTitle: FC<Props> = ({text, className}) => {
    return (
        <h1 className={`${className}`} dangerouslySetInnerHTML={{__html: text}}/>
    );
};

export default MainTitle;