import axios from "axios";
import { API_URL } from "../../helpers/crud-helper/consts";

const PRODUCT_URL = `${API_URL}/product`;

const shopIndex = (
  currentLanguageCode: string,
  page: any,
  limit: number,
  project: string | null | undefined,
  search: string | null,
  sort: string | null
): Promise<any> => {
  return axios
    .get(
      `${API_URL}/shop?language=${currentLanguageCode}&limit=${limit}&page=${page}&project=${project}&search=${search}&sort=${sort}`
    )
    .then((response) => response.data.data);
};

const getProducts = (
  currentLanguageCode: string,
  page: any,
  limit: number
): Promise<any> => {
  return axios
    .get(
      `${PRODUCT_URL}?language=${currentLanguageCode}&limit=${limit}&page=${page}`
    )
    .then((response) => response);
};
const getSecondaryProducts = (
  currentLanguageCode: string,
  page: any,
  limit: number
): Promise<any> => {
  return axios
    .get(
      `${API_URL}/secondary-products?language=${currentLanguageCode}&limit=${limit}&page=${page}`
    )
    .then((response) => response);
};
const showProduct = (
  project: any,
  product: any,
  currentLanguageCode: string
): Promise<any> => {
  return axios
    .get(
      `${API_URL}/project/${project}/product/${product}?language=${currentLanguageCode}`
    )
    .then((response) => response.data.data);
};

const getProductsByProject = (
  currentLanguageCode: string,
  project: number
): Promise<any> => {
  return axios
    .get(`${PRODUCT_URL}/project/${project}?language=${currentLanguageCode}`)
    .then((response) => response);
};

const getProductAvailableNumbers = (id: any): Promise<any> => {
  return axios
    .get(`${PRODUCT_URL}/${id}/available`)
    .then((response) => response);
};

const generateRandomNumber = (id: any): Promise<any> => {
  return axios
    .get(`${PRODUCT_URL}/${id}/generate-number`)
    .then((response) => response);
};

const getRentBuyProducts = (currentLanguageCode: any): Promise<any> => {
  return axios
    .get(`${API_URL}/rent-buy?language=${currentLanguageCode}`)
    .then((response) => response);
};

const getPromoCode = (currentLanguageCode: any): Promise<any> => {
  return axios
    .get(`${API_URL}/promo-code?language=${currentLanguageCode}`)
    .then((response) => response);
};

const addBundleToCart = (currentLanguageCode: any): Promise<any> => {
  return axios
    .get(`${API_URL}/bundle-promo-code?language=${currentLanguageCode}`)
    .then((response) => response);
};

const ifa = (currentLanguageCode: any): Promise<any> => {
  return axios
    .get(`${API_URL}/ifa?language=${currentLanguageCode}`)
    .then((response) => response);
};
const animalWelfare = (currentLanguageCode: any): Promise<any> => {
  return axios
    .get(`${API_URL}/animal-welfare?language=${currentLanguageCode}`)
    .then((response) => response);
};

const mothersProduct = (currentLanguageCode: any): Promise<any> => {
  return axios
    .get(`${API_URL}/mothers-product?language=${currentLanguageCode}`)
    .then((response) => response);
};

export {
  animalWelfare,
  mothersProduct,
  getProducts,
  shopIndex,
  getSecondaryProducts,
  getProductsByProject,
  showProduct,
  getProductAvailableNumbers,
  generateRandomNumber,
  getRentBuyProducts,
  getPromoCode,
  addBundleToCart,
  ifa,
};
