import {TFunction} from "i18next";
import Cookies from "js-cookie";
import {createContext, FC, ReactNode, useContext} from "react";
import {useTranslation} from "react-i18next";

export type WithChildren = {
    children?: ReactNode
}

type LanguageContextProps = {
    currentLanguageCode: string;
    t: TFunction;
}

const initLanguageContextPropsState = {
    currentLanguageCode: 'en',
    t: (key: string) => key
}

const LanguageContext = createContext<LanguageContextProps>(initLanguageContextPropsState as LanguageContextProps)

const useLanguage = () => {
    return useContext(LanguageContext)
}

const LanguageProvider: FC<WithChildren> = ({children}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const {t} = useTranslation();

    return (
        <LanguageContext.Provider value={{currentLanguageCode, t}}>
            {children}
        </LanguageContext.Provider>
    )
}

const LanguageInit: FC<WithChildren> = ({children}) => {

    return <>{children}</>
}

export {LanguageProvider, LanguageInit, useLanguage}