import {API_URL} from "../../helpers/crud-helper/consts";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Partner, PartnersData} from "../../models/Partner";
import {PartnerSubmissionFormFields} from "../../sections/partner/components/PartnerForm";
import {createFormData} from "../../helpers/form";

const PARTNER_URL = `${API_URL}/partners`

export const getPartners = async (currentLanguageCode: string): Promise<PartnersData> => {
    return await axios
        .get(`${PARTNER_URL}?language=${currentLanguageCode}`)
        .then((response: AxiosResponse<PartnersData>) => response.data)
        .catch((error) => {
            return error;
        });
}

export const storePartnerSubmission = async (form: PartnerSubmissionFormFields): Promise<Partner | AxiosError | undefined> => {
    const formData = createFormData(form)

    return await axios.post(PARTNER_URL, formData)
        .then(res => res.data.data)
        .catch((error) => {
            return error;
        });
}