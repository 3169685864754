import {Partner} from "../../../models/Partner";
import {SectionContent} from "./models";

export const filterPartnersByType = (partners: Partner[], type: string): Partner[] => {
    return partners.filter(partner => partner.type === type);
};

export const filterPartnersBySlug = (partners: Partner[], slug: string): Partner | undefined => {
    return partners.find(partner => partner.slug === slug);
};

export const filterSectionsContentBySlug = (sectionsContent: SectionContent[], type: string) => {
    return sectionsContent.find((sectionContent: SectionContent) => sectionContent.type === type);
};