import {CartItem} from "../models/Product";

type CartState = {
    items: CartItem[];
};

export type CartAction =
    | { type: "ADD_TO_CART"; product: CartItem }
    | { type: "REMOVE_ONE_FROM_CART"; id: number }
    | { type: "REMOVE_ONE_NUMBERED_PRODUCT_FROM_CART"; id: number; number: number }
    | { type: "ADD_NUMBERED_PRODUCT_TO_CART"; product: CartItem }
    | { type: "REMOVE_FROM_CART"; id: number }
    | { type: "CLEAR_CART" };

const initialState: CartState = {
    items: [],
};

const cartReducer = (state: CartState = initialState, action: CartAction): CartState => {
    let newState;
    switch (action.type) {
        case "ADD_TO_CART":
            const existingItem = state.items.find(item => item.id === action.product.id);
            if (existingItem) {
                newState = {
                    ...state,
                    items: state.items.map(item =>
                        item.id === action.product.id
                            ? {...item, quantity: item.quantity + (action.product.quantity ?? 1)}
                            : item
                    ),
                };
            } else {
                newState = {
                    ...state,
                    items: [...state.items, action.product],
                };
            }
            break;
        case "ADD_NUMBERED_PRODUCT_TO_CART" :
            const existingNumberedItem = state.items.find(item => item.id === action.product.id && item.number === action.product.number);
            if (!existingNumberedItem) {
                newState = {
                    ...state,
                    items: [...state.items, action.product],
                };
            } else {
                newState = {
                    ...state
                }
            }
            break;
        case "REMOVE_ONE_FROM_CART":
            newState = {
                ...state,
                items: state.items
                    .map(item =>
                        item.id === action.id && item.quantity > 0
                            ? {...item, quantity: item.quantity - 1}
                            : item
                    )
                    .filter(item => item.quantity > 0),
            };
            break;
        case "REMOVE_ONE_NUMBERED_PRODUCT_FROM_CART":
            newState = {
                ...state,
                items: state.items.filter(item => !(item.id === action.id && item.number === action.number)),
            };
            break;
        case "REMOVE_FROM_CART":
            newState = {
                ...state,
                items: state.items.filter(item => item.id !== action.id),
            };
            break;
        case "CLEAR_CART":
            newState = initialState;
            break;
        default:
            newState = state;
    }

    saveCartToLocalStorage(newState);

    return newState;
};

const saveCartToLocalStorage = (cartState: CartState) => {
    try {
        const serializedState = JSON.stringify(cartState);
        localStorage.setItem('cart', serializedState);
    } catch (e) {
        console.error('Could not save cart to local storage', e);
    }
};

export default cartReducer;