import axios from "axios";
import { AuthModel, User } from "../../models/User";
import { API_URL } from "../../helpers/crud-helper/consts";

export const GET_USER_PROFILE = `${API_URL}/profile`;
export const LOGIN_URL = `${API_URL}/login`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function getUserByToken(token: string) {
  return axios.get<User>(GET_USER_PROFILE, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    transformResponse: [
      function (data) {
        return JSON.parse(data).data;
      },
    ],
  });
}
