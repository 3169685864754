import React, {FC} from 'react';

type Props = {
    text?: any
    className?: any
}

const MainText: FC<Props> = ({text, className}) => {
    return (
        <p className={`${className}`} dangerouslySetInnerHTML={{__html: text}}/>
    );
};

export default MainText;